import React, { useContext, useEffect } from "react";
import styled from "@emotion/styled";
import { Box, useThemeUI } from "theme-ui";
import getStaticPageProps from "utils/getStaticPageProps";
import Page from "components/Page";
import usePageData from "hooks/usePageData";
import { AppContext } from "contexts/AppContext";

const SLUG = "home";

const index = ({ preview }) => {
  const { data } = usePageData(SLUG, null, preview);
  const { data: navigationData } = usePageData("navigation", "/", preview);
  const {
    setCurrentNavLinks,
    setHomeOverview,
    setCurrentSubPage,
    setCurrentContactNav,
  } = useContext(AppContext);
  const { setColorMode } = useThemeUI();

  useEffect(() => {
    const { overviewBody } = data?.pageCollection?.items[0] || {};
    setHomeOverview(overviewBody);
  }, []);

  useEffect(() => {
    const navigationCollection = navigationData?.navigationCollection?.items[0];
    const { contactLinkText, contactLinkUrl } = navigationCollection;
    setCurrentContactNav({ label: contactLinkText, url: contactLinkUrl?.slug });

    const navigationItems =
      navigationData?.navigationCollection?.items[0]?.navigationItemsCollection
        ?.items || [];
    let navLinks = navigationItems.map(({ title, pageLink }) => {
      const navSlug = pageLink?.slug;
      return {
        label: title,
        href: navSlug,
      };
    }, []);
    setCurrentSubPage({
      title: navigationCollection.title,
      slug: navigationCollection.slug,
    });
    setCurrentNavLinks(navLinks);
    switch (navigationCollection?.theme) {
      case "Light":
        setColorMode("light");
        break;
      case "Dark":
        setColorMode("default");
        break;
      default:
        setColorMode("default");
    }
  }, []);
  return <Page data={data} />;
};
export default index;

export const getStaticProps = async ({ preview = false }) =>
  await getStaticPageProps(SLUG, null, preview);
